@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

@font-face {
	font-family: 'Helvetica Neue';
	src: url('../../dist/font/HelveticaNeue.woff2') format('woff2'),
		url('../../dist/font/HelveticaNeue.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Helvetica Neue';
	src: url('../../dist/font/HelveticaNeue-Bold.woff2') format('woff2'),
		url('../../dist/font/HelveticaNeue-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

html {
	background: $black;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 1;
	scroll-behavior: smooth;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

.split {
	display: flex;
	flex-direction: column;

	.side {
		flex: 1;
	}

	@include tablet-up {
		flex-direction: row;
	}
}

.desktop {
	@include tablet-down {
		display: none !important;
	}
}

.mobile {
	@include tablet-up {
		display: none !important;
	}
}

h1,
h2,
h3,
h4,
h5,
p,
ul,
ol {
	margin: 0;
}

h2 {
	font-size: 30px;
	margin-bottom: 60px;
}

.btn {
	background-color: $white;
	color: $black;
	text-align: center;
	text-transform: uppercase;
	font-size: 12px;
	padding: 12px 24px;
	display: inline-block;
}

header {
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 9;
	padding: 20px 0;

	.container {
		>.split {
			gap: 10px;

			>.side {
				.social {
					list-style: none;
					padding: 0;
					display: flex;
					justify-content: center;
					gap: 20px;

					li {
						a {
							font-size: 20px;
							color: $black;
						}
					}
				}

				.split {
					gap: 10px;

					.side {
						.logo {
							display: block;
							margin: 0 auto;
						}

						.nav {
							list-style: none;
							padding: 0;
							display: flex;
							justify-content: center;
							gap: 20px;

							li {
								a {
									color: $black;
									font-size: 14px;
								}
							}
						}
					}
				}
			}
		}
	}

	@include tablet-up {
		padding: 40px 0;

		.container {
			>.split {
				justify-content: space-between;

				.social {
					justify-content: flex-end;
				}

				>.side {
					max-width: max-content;

					.split {
						justify-content: flex-start;
						gap: 20px;

						.side {
							max-width: max-content;

							.logo {
								margin: 0;
							}
						}
					}
				}
			}
		}
	}
}

main {
	position: relative;

	#hero {
		img {
			width: 100%;
			height: auto;
		}
	}

	#newsletter {
		
		background-image: url('../../dist/img/tour-mobile.jpeg');
		background-size: cover;
		padding: 80px 0;

		@include tablet-up {
			background-image: url('../../dist/img/tour.png');
			padding: 160px 0;
		}

		h2{
			text-transform: uppercase;
		}

		.container {
			max-width: rem(800);
			padding: 15px;
			background-color: #96969655;
			text-align: center;

			iframe {
				margin: 0 auto;
			}
		}
	}

	#tour {

		background-color: $white; 
		color: $black;
		padding: 80px 0;
		
		@include tablet-up {
			padding: 160px 0;
		}
		.seated-events-table {
			border-top: none;

			.seated-event-row {
				border-bottom: none;

				.seated-event-description-cells {
					display: flex;
					flex: 1;
					flex-direction: column;
					gap: 20px;
					&::after{
						content:'';
						width: rem(100);
					}

					@include tablet-up {
						flex-direction: row;
						.seated-event-venue-location {
							width: rem(240);
							text-align: center;
						}
					}

					.seated-event-date-cell {
						flex: 1;
						font-size: 20px;
						display: flex;
						align-items: center;
						@media screen and (max-width: 580px){
							justify-content: center;
						}
					}

					.seated-event-venue-cell {
						font-size: 20px;
						text-transform: uppercase;
						flex: 1;
						display: flex;
						flex-direction: column;
						gap: 8px;

						.seated-event-venue-name {
							display: none; 
						}

					}

					.seated-event-details-cell {
						display: none;
					}
				}

				.seated-event-link {
					background-color: transparent;
					border: none;
					padding: 0;
					color: $black;
					font-weight: 700;
					font-size: 20px;
				}
			}
			
			.seated-follow-text{
				font-weight: 400;
				letter-spacing: .1em;
				text-transform: uppercase;
			}
		}
	}
}

footer {
	text-align: center;
	padding: 30px 0;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	font-size: 12px;
}


.vault-btn {
	background-color: $black;
	color: $white !important;
	text-transform: uppercase;
	font-size: 12px;
	padding: rem(6) rem(16) !important;
	border: 1px solid $black;

	@include hover-focus {
		background-color: $white;
		color: $black !important;
	}
}